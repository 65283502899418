import { React } from "react";
import { TypeAnimation } from "react-type-animation";
import "../Styling/App.css";

export default function AboutMe() {
  const main = "I'm a";
  const timer = 1500;
  return (
    <div className="aboutMe-container">
      <div>
        <h2>Welcome to</h2>
        <h1 style={{ fontSize: "42px" }} className="text-3d">
          <code> Moudhi Albannai's</code>
        </h1>
        <h3>Website</h3>
        <TypeAnimation
          sequence={[
            main + " Computer Engineer",
            timer,
            main + " Full Stack Developer",
            timer,
            main + " Project Lead",
            timer,
            main + " Coordanitor",
            timer,
            main + " Content Creator",
            timer,
            main + "n Educator",
            timer,
          ]}
          wrapper="span"
          speed={40}
          style={{ fontSize: "26px", display: "inline-block" }}
          repeat={Infinity}
        />
        <p>Depending on what you need 😎</p>
      </div>
    </div>
  );
}
