import "./Styling/App.css";
// import CountDownNav from "./Navbar/CountDownNav";
import AboutMe from "./Pages/AboutMe";
import { BrowserRouter } from "react-router-dom";
// import RespNavbar from "./Navbar/RespNavbar";

function App() {
  return (
    <BrowserRouter>
    {/* Commented out the countdown */}
      {/* <CountDownNav /> */}
      {/* <RespNavbar /> */} 
      <div className="AppDiv">
        <AboutMe />
      </div>
    </BrowserRouter>
  );
}

export default App;
